import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const PatientsPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  questions,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        {/* <div className="support-banner has-text-centered">
          <h1>Bay Of Plenty Collection Services Staffing Crisis</h1>
          <div>
            <p>
              We are most grateful for your support and understanding while we
              work through the unprecedented staffing issues we are experiencing
              in the Bay of Plenty.
            </p>
            <p>
              We sincerely apologise for the significant impact this is having
              on wait times at our collection facilities.
            </p>
          </div>
        </div> */}
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            {questions && questions.length ? (
              <div style={{ marginTop: `2rem` }}>
                <div className="columns is-multiline is-desktop">
                  {questions.map((item) => (
                    <div
                      key={item.question + `question`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <Link
                          className="has-text-primary"
                          to={"../" + item.answer}
                        >
                          <h2 className="has-text-primary">{item.question}</h2>
                          <div className="questionImage">
                            <div className="is-square margin-top-0 nav-tile">
                              <PreviewCompatibleImage
                                imageInfo={item.picture.image}
                                style={{
                                  display: "flex",
                                  paddingTop: 0,
                                  width: "100%",
                                  height: "250px",
                                  justifyContent: "center",
                                  alignItems: "flex-end",
                                  backgroundSize: "cover",
                                  borderRadius: "15px",
                                }}
                              />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

PatientsPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      picture: PropTypes.arrayOf(
        PropTypes.shape({
          alt: PropTypes.string,
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        })
      ),
    })
  ),
};

const Patients = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PatientsPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        questions={post.frontmatter.questions}
      />
    </Layout>
  );
};

Patients.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Patients;

export const pageQuery = graphql`
  query PatientsByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        questions {
          question
          answer
          picture {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
